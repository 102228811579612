import React from 'react';
import { Box, styled } from '@mui/system';
import theme, { BREAKPOINTS, COLORS, MOBILE_SPACING } from '../../utils/theme';
import RequestResponse from '../../components/api-ref/RequestResponse';
import EndPointInfo from '../../components/api-ref/EndPointInfo';
import ParameterInfo from '../../components/api-ref/ParameterInfo';
import parse from 'html-react-parser';
import Typography from '../../components/common/Typography';
import SEO from '../../components/common/Seo';
import ReactMarkdown from 'react-markdown';
import MDXComponents from '../../components/docs/MDXComponents';
import rehypeRaw from 'rehype-raw';
import AuthInfo from '../../components/api-ref/AuthInfo';
import LoadingState from '../../components/common/LoadingState';
import { appendRouteToUrl } from '../../utils/helper';
import { checkEnv } from '../../utils/helpers';
import { getPrivatePage } from '../../components/common/SecurityWrapper';

const useStyles = {
  content: {
    display: 'flex',
    marginTop: '24px',
    [BREAKPOINTS.MOBILE]: {
      flexDirection: 'column',
      rowGap: theme.spacing(4),
    },
  },
  header: {
    padding: theme.spacing(0, 10),
  },
  descriptionStyle: {
    marginTop: '8px',
    paddingLeft: '18px',
    color: COLORS.TEXT_MEDIUM_EMPHASIS,
    paddingBottom: '8px',
  },
  summaryStyle: {
    paddingLeft: '18px',
  },
  endpointTagStyle: {
    marginTop: '18px',
    paddingLeft: '18px',
  },
  rightPanelContents: {
    display: 'flex',
    justifyContent: 'start',
  },
  headerStyle: {
    marginTop: '18px',
    paddingLeft: theme.spacing(10),
    paddingBottom: theme.spacing(2),
  },
  displayNoneStyle: {
    display: 'none',
  },
};
const Container = styled('div')(({ theme }) => ({
  paddingBottom: '8px',
  margin: '0px auto',
  maxWidth: '1028px',
  width: `calc( 100% - ${MOBILE_SPACING.MARGIN} )`,
  [BREAKPOINTS.MOBILE]: {
    width: 'unset',
    maxWidth: `calc( 100% - ${MOBILE_SPACING.MARGIN} )`,
    margin: '0px 20px',
  },
}));

const InfoStyle = styled('div')(({ theme }) => ({
  maxWidth: '1028px',
  margin: '44px auto',
  marginBottom: '0px',
  width: `calc( 100% - ${MOBILE_SPACING.MARGIN} )`,
  [BREAKPOINTS.MOBILE]: {
    width: 'unset',
    maxWidth: `calc( 100% - ${MOBILE_SPACING.MARGIN} )`,
    margin: 'auto 20px',
    marginTop: theme.spacing(3),
  },
}));

const LeftPanel = styled('div')(({ theme }) => ({
  width: '50%',
  [BREAKPOINTS.MOBILE]: {
    minWidth: 'unset',
    width: '100%',
  },
}));

const RightPanel = styled('div')(({ theme }) => ({
  width: '50%',
  marginLeft: theme.spacing(10),
  [BREAKPOINTS.MOBILE]: {
    minWidth: 'unset',
    width: '100%',
    marginLeft: '0px',
  },
}));

const snippetWrapper = {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(6),
} as const;


const ApiTemplate = (serverData) => {
  const { apiData, definitions, components, info, securityDefinition, isPrivate } =
    serverData;
  const securityDef = securityDefinition
    ? securityDefinition
    : components.securitySchemes;
  const [isLoading, setIsLoading] = React.useState(true);
  React.useEffect(() => {
    setTimeout(function () {
      setIsLoading(false);
    }, 0);
  }, []);
  React.useEffect(() => {
    if (typeof window !== 'undefined' && isLoading === false) {
      let hash = window.location.hash;
      if (hash && hash.charAt(hash.length - 1) === '/') {
        hash = hash.substr(0, hash.length - 1);
      }
      appendRouteToUrl(hash);
    }
  }, [isLoading]);

  function buildAPIRef() {
    return Object.keys(apiData).map((key) => {
      const paths = apiData[key];
      // This array is to store env enable value for each api
      const arr:any = [];
      let envEnabled = true;
      paths.forEach(pathData => {
        arr.push(checkEnv(pathData));
      });
      if(arr.indexOf(true) === -1)
      {
        envEnabled = false;
      }
      return (
        !envEnabled ? <></> :
        <Container key={key}>
          <Box>
            {key && 
              <Typography
                variant="h1"
                id={`${key}`}
                sx={useStyles.endpointTagStyle}
                key={key}
              >
                {key}
              </Typography>
            }
            {paths.map((pathData, index) => {
              return (
                !arr[index] ? <></> :
                <Box
                  key={pathData.path + '_' + pathData.method}
                  sx={useStyles.content}
                >
                  <LeftPanel>
                    <Box>
                      <Typography
                        variant="h2"
                        id={`${pathData.operationId}`}
                        sx={useStyles.summaryStyle}
                      >
                        {pathData.summary}
                      </Typography>
                      <EndPointInfo
                        method={pathData.method}
                        path={pathData.path}
                      />
                      {pathData.description && 
                        <Typography
                          type="DESKTOP_BODY_PARAGRAPH"
                          sx={useStyles.descriptionStyle}
                        >
                          {parse(pathData.description)}
                        </Typography>
                      }
                      <ParameterInfo
                        items={pathData.parameters}
                        definitions={definitions}
                        components={components}
                        body={pathData.requestBody}
                        responses={pathData.responses?.[200]}
                      />
                    </Box>
                  </LeftPanel>
                  <RightPanel>
                    <Box sx={useStyles.rightPanelContents}>
                      <Box sx={snippetWrapper}>
                        <RequestResponse
                          items={pathData.snippets}
                          type="request"
                          isJSONFoldable={true}
                          snippetId={`${pathData.operationId}` + '(request)'}
                          isAPI={true}
                        />
                        <RequestResponse
                          items={pathData.responseList}
                          type="response"
                          isJSONFoldable={true}
                          snippetId={`${pathData.operationId}` + '(response)'}
                          isAPI={true}
                        />
                      </Box>
                    </Box>
                  </RightPanel>
                </Box>
              );
            })}
          </Box>
        </Container>
      );
    });
  }
  const shortCodes = {
    ...MDXComponents,
    securitydefinitions: (props) => <AuthInfo details={securityDef}></AuthInfo>,
  };

  return (
    <>
      <SEO
        title={info ? info.title : ''}
        description={info ? info.description : ''}
      />
      {isLoading && <LoadingState />}
      {
        <InfoStyle>
          <Box sx={!isLoading ? {} : useStyles.displayNoneStyle}>
            <ReactMarkdown rehypePlugins={[rehypeRaw]} components={shortCodes}>
              {info.description}
            </ReactMarkdown>
          </Box>
        </InfoStyle>
      }
      {
        <Box sx={!isLoading ? {} : useStyles.displayNoneStyle}>
          {buildAPIRef()}
        </Box>
      }
    </>
  );
};

export default ({ serverData }) => {
  if(serverData.isPrivate)
  {
    return getPrivatePage(serverData.apiData);
  }
  else {
    return ApiTemplate(serverData);
  }
};

const isLocalhost = (headers) => {
  return headers && headers.startsWith('localhost');
};

export async function getServerData(context) {
  const OpenAPISnippet = require('openapi-snippet');
  const SwaggerParser = require('swagger-parser');
  const { getApiData, getSideNavList } = require('../../utils/helpers/index');
  const {readFileSync} = require('fs');

  let title;
  let apiDetails;

  const apiJson = JSON.parse(readFileSync(context.pageContext.absolutePath, 'utf-8'));
  const path = context.pageContext.apiName;
  const isRunningOnLocalhost = isLocalhost(context.headers.get('host'));
  const checkTrailingSlash = (servers) => {
    return servers.map((server) => {
      const url = server.url;
      if (url.charAt(url.length - 1) === '/') {
        return { url: url.substr(0, url.length - 1) };
      }
      return { url: url };
    });
  };

  const processAndGetAPIDetails = async (api) => {
    api.servers = checkTrailingSlash(api.servers);
    api.basePath = api.basePath ? api.basePath : '/';
    const targets = ['shell_curl'];
    const snippets = await OpenAPISnippet.getSnippets(api, targets);
    const json = await SwaggerParser.dereference(api, {
      dereference: { circular: 'ignore' },
    });
    
    const apiData = getApiData(json, snippets, path);
    const sideNavList = getSideNavList(json, path);

    return {
      apiData: apiData,
      sideNavList: sideNavList,
      info: json.info,
      definitions: json.definitions,
      components: json.components,
      securityDefinition: json.securityDefinitions,
      isPrivate: false
    };
  };

  let cookies:any = [];
  if(context.headers.get('cookie'))
  {
    cookies = context.headers.get('cookie').split(';');
  }
  let sessionId = '';
  for (const element of cookies) {
    if (element.trim().match('^session-id=')) {
      sessionId = element.replace('session-id=', '').trim();
    }
  }

  const session = async() => {
    return fetch(`${process.env.GATSBY_OKTA_DOMAIN_URL}/api/v1/sessions/${sessionId}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `SSWS ${process.env.GATSBY_OKTA_API_KEY}`
      }
    })
    .then((response) => response.json())
    .then((data) => {
      if(data && data.status === 'ACTIVE')
      {
        return true;
      }
      else{
        return false;
      }
    })
    .catch((error) => {
      return false;
    });
  };
  
  const checkIsPrivate = (api, isRunningOnLocalhost) => {
    const { tags } = api; 
    if (tags) {
      for (const element of tags) {
        if (element.name === 'private') {
          if (isRunningOnLocalhost) {
            return false;
          }
          title = element.description;
          return true;
        }
      }
    } else {
      return false;
    }
  };

  if(checkIsPrivate(apiJson, isRunningOnLocalhost))
  {
    if(sessionId && (await session() || isRunningOnLocalhost))
    {
      apiDetails = await processAndGetAPIDetails(apiJson);
    }
    else {
      const sideNavList = getSideNavList(apiJson, path);
      apiDetails = {
        apiData: title,
        sideNavList: sideNavList,
        isPrivate: true
      };
    }
  }
  else{
    apiDetails = await processAndGetAPIDetails(apiJson);
  }

  return {
    props: {
      ...apiDetails,
      type: 'api',
    }
  };
}